import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact.js";
import Aboutus from "./pages/Aboutus/Aboutus.js";
import Projects from "./pages/Gallery/Projects.js";
import Services from "./pages/Services/Services";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import SideNav from "./components/SideNav";
import { useState } from "react";
import ThirdPartyServices from "./pages/ThirdPartyServices.js";
import ScrollToTop from "./components/ScrollToTop.js";

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const isProjectsPage = location.pathname === "/projects";

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} sticky={isProjectsPage} />
      <SideNav show={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/third-party-services" element={<ThirdPartyServices />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
