import React from "react";
import Section2 from "./Section2";
import { Helmet } from "react-helmet-async";
import HeroBannerSection from "../../sections/HeroBanner/HeroBannerSection";
import OurServices from "../../sections/OurServices/OurServices";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Paragon | Professional Design & Build Services in Birmingham and
          surrounding areas
        </title>
        <meta
          name="description"
          content="Looking for a reliable and experienced design and build company in Birmingham? Look no further than our team of experts, serving the surrounding areas. Contact us today to turn your dream project into a reality."
        />
      </Helmet>
      <div className="w-full h-full m-0 p-0">
        <HeroBannerSection />
        <OurServices />
        <Section2 />
      </div>
    </>
  );
}

export default Home;
