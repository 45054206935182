import React from "react";
import "./ContactBanner.css";
import { CgMail } from "react-icons/cg";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";

const ContactBanner = () => {
  const socials = [
    {
      icon: FaInstagram,
      url: "https://www.instagram.com/paragon.a.c?igsh=dGUzZ3Z6eGt3ZnRm",
    },
    {
      icon: FaFacebook,
      url: "https://www.facebook.com/profile.php?id=61561859695503",
    },
    {
      icon: FaLinkedinIn,
      url: "https://www.linkedin.com/paragon-architecture-construction-uk",
    },
  ];

  const info = [
    {
      icon: BiSolidPhone,
      title: "024 7772 1468",
    },
    {
      icon: CgMail,
      title: "info@pacltd.co.uk",
    },
    {
      icon: CiLocationOn,
      title: "Based in Midlands",
    },
  ];

  return (
    <div className="contact-banner-container">
      <div className="contact-banner">
        <h1 className="conthrax">CONTACT US</h1>
        <p>
          Need assistance or further information? Reach out to us via details
          below and our Team will be happy to help with your project needs.
        </p>
        <div className="contact-banner-info">
          {info.map((item, index) => (
            <div key={index} className="contact-banner-info-items">
              <span>
                <item.icon />
              </span>
              <p>{item.title}</p>
            </div>
          ))}

          <div className="contact-banner-socials">
            <p>Other Ways to Connect: </p>
            {socials.map((item, index) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className="contact-banner-info-items"
              >
                <span>
                  <item.icon />
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="contact-banner-image">
        <img src={"/assets/images/contact/Contact-us.png"} alt="contact-us" />
      </div>
    </div>
  );
};

export default ContactBanner;
