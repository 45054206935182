import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./HeroBanner.css";

const HeroBanner = () => {
  const navigate = useNavigate();

  const handleQuoteClick = () => {
    navigate("/contact");
  };

  return (
    <div className="hero-container">
      <div className="hero-overflow-hidden">
        <h1 className="hero-intro-title conthrax pulse-animation">
          DESIGN & BUILD
          <br />
          SPECIALISTS
        </h1>
      </div>
      <div className="hero-intro-buttons">
        <motion.div
          className="hero-btn2"
          onClick={handleQuoteClick}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p>FREE Quote</p>
          <FontAwesomeIcon icon={faArrowRight} className="hero-btn2-icon" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeroBanner;
