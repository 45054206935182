import React from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import "./Count.css";

const Count = () => {
  const { ref: countRef, inView: countInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const count = [
    { title: "Years of Combined Experience", start: 0, end: 30, symbol: "+" },
    { title: "Completed Projects", start: 800, end: 1000, symbol: "+" },
    { title: "All RIBA Project Stages", start: 0, end: 7, symbol: "" },
    { title: "Planning Permission Success", start: 0, end: 95, symbol: "%" },
  ];

  return (
    <div className="count-container" ref={countRef}>
      {count.map((data, index) => (
        <motion.div
          key={index}
          className="count-text"
          initial={{ opacity: 0, y: 50 }}
          animate={countInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <span>
            <CountUp start={data.start} end={data.end} duration={5} />
            <span>{data.symbol}</span>
          </span>
          <p>{data.title}</p>
        </motion.div>
      ))}
    </div>
  );
};

export default Count;
