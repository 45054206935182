import React from "react";
import "./HeroBannerSection.css";
import Count from "../../components/Count/Count.js";
import HeroBanner from "../../components/HeroBanner/HeroBanner.js";

const HeroBannerSection = () => {
  return (
    <div>
      <HeroBanner />
      <Count />
    </div>
  );
};

export default HeroBannerSection;
