import React from "react";
import "./AboutBanner.css";

const AboutBanner = () => {
  return (
    <div className="about-banner-container">
      <div className="about-banner-image">
        <img src="/assets/images/about-us.png" alt="aboutus" />
      </div>
      <div className="about-banner-right">
        <h1 className="conthrax">ABOUT</h1>
        <h3 className="conthrax">
          Experience the Paragon difference - where exceptional design meets
          unparalleled build.
        </h3>
        <p className="about-para font-light">
          Your space should be a true reflection of your vision, whether it’s a
          home that fits your lifestyle or a commercial place that inspires
          success. At Paragon Architecture and Construction, we specialise in
          creating tailored residential and commercial spaces with meticulous
          attention to detail and a commitment to your satisfaction.
        </p>
      </div>
    </div>
  );
};

export default AboutBanner;
