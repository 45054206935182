import "./Footer.css";
import { CgMail } from "react-icons/cg";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import PrivacyPolicy from "../PrivacyPolicy.pdf";
import { CiLocationOn } from "react-icons/ci";
import ListingComponent from "../listing/listing";

function Footer() {
  const socials = [
    {
      icon: FaInstagram,
      id: "instagram",
      url: "https://www.instagram.com/paragon.a.c?igsh=dGUzZ3Z6eGt3ZnRm",
    },
    {
      icon: FaFacebook,
      id: "facebook",
      url: "https://www.facebook.com/profile.php?id=61561859695503",
    },
    {
      icon: FaLinkedinIn,
      id: "linkedin",
      url: "https://www.linkedin.com/paragon-architecture-construction-uk",
    },
  ];

  const details = [
    { title: "Home", url: "/" },
    { title: "Services", url: "/services" },
    { title: "Projects", url: "/projects" },
    { title: "About", url: "/about" },
    { title: "Contact", url: "/contact" },
  ];

  const tools = [
    { title: "Home Project Guide", url: "" },
    { title: "Free Quote Generator", url: "" },
    { title: "Extension Cost Calculator", url: "" },
    { title: "FAQs", url: "", id: "home_project_guide" },
    { title: "Third Party Services", url: "" },
  ];

  return (
    <div className="footer">
      <img src="/assets/images/logo.png" alt="logo" className="header-logo" />

      <div className="footer-top">
        <div className="footer-section-1">
          <h1>Contact</h1>
          <div className="footer-contact">
            <span>
              <BiSolidPhone />
            </span>
            <p>024 7772 1468</p>
          </div>
          <div className="footer-contact">
            <span>
              <CgMail />
            </span>
            <p>info@pacltd.co.uk</p>
          </div>
          <div className="footer-contact">
            <span>
              <CiLocationOn />
            </span>
            <p>Based in Midlands</p>
          </div>
          <div className="footer-socials">
            <p>Reach Out to Us: -</p>
            <div className="content">
              {socials.map((item) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                >
                  <item.icon size={25} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="footer-flex">
          <div className="footer-section-2">
            <ListingComponent data={details} title={"Menu"} />
          </div>
          <div className="footer-section-3">
            <ListingComponent data={tools} title={"Useful Tools"} />
          </div>
        </div>
      </div>
      <div className="footer-license">
        <p>
          © 2024 PARAGON ARCHITECTURE AND CONSTRUCTION. ALL RIGHTS RESERVED. |
          <a href={PrivacyPolicy} target="_blank" rel="noreferrer">
            PRIVACY POLICY & LEGAL NOTICE
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
