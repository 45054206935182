import React from "react";
import "./listing.css";
import { Link } from "react-router-dom";

const ListingComponent = ({ data, title }) => {
  return (
    <div className="listing-container">
      <h2 className="">{title}</h2>
      <div className="list">
        {data.map((item, index) => (
          <Link key={index} to={item.url} className="list-title">
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ListingComponent;
