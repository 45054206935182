import React from "react";
import "./Aboutus.css";
import { motion } from "framer-motion";
import ContactBtn from "../../components/ContactBtn/ContactBtn";
import { Helmet } from "react-helmet-async";
import AboutBanner from "../../components/AboutBanner/AboutBanner";
import AboutParagon from "../../components/AboutParagon/AboutParagon";

function Aboutus() {
  const data = [
    {
      title: "YAS",
      class: "member-1",
      url: "",
      designation: "Principal Construction Manager",
      description:
        "Driven by a passion for building excellence, Yas manages projects from inception to completion, ensuring they are finished on schedule, within budget, and to the highest quality standards. He is dedicated to maintaining effective communication throughout all construction projects",
    },
    {
      title: "MIKE",
      class: "member-2",
      url: "https://www.linkedin.com/in/mikecanouk/",
      designation: "Chartered Consultant",
      description:
        "With experience in over 800 residential projects over the past 10 years, and a passion for creative designs, Mike is a Chartered Architect dedicated to working closely with you to maximise your project's potential beyond your expectations to bring your dreams to life.",
    },
    {
      title: "THAO",
      class: "member-3",
      url: "https://www.linkedin.com/in/thaocostauk/",
      designation: "Chief Operating Officer",
      description:
        "Equipped with over 10 years expertise in customer centricity, Thao is committed to personalising every project journey to your needs. She ensures immense effort for a seamless experience with Paragon at every stage of Design & Build, especially intermediary requests",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Paragon Architecture & Construction | About Us</title>
        <meta
          name="description"
          content="Paragon Architecture & Construction is a trusted, locally owned business based in Bithe Midlands. Learn about our mission, values, and expertise in providing top-tier design and construction services for residential and commercial projects."
        />
      </Helmet>
      <motion.div className="aboutus-container mb-5">
        <AboutBanner />
        <AboutParagon />

        <div className="specialists">
          <h1>OUR SPECIALISTS</h1>
          <div className="specialists-div">
            {data.map((item, index) => (
              <div div className="member-card-con">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className={`member-cards ${item.class}`}
                >
                  <div className="member-details">
                    <p>{item.title}</p>
                  </div>
                </a>
                <div className="">
                  <p className="designation">{item.designation}</p>
                  <p className="description">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="about-contact-btn">
          <ContactBtn justify="center" />
        </div>
      </motion.div>
    </>
  );
}

export default Aboutus;
