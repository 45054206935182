import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import ContactInfoBox from "../../components/ContactInfoBox/ContactInfoBox";

function Contact() {
  const form = useRef();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    setRecaptchaToken(null);
  };

  const inputs = [
    {
      type: "text",
      value: fullName,
      name: "user_name",
      onchange: setFullName,
      title: "Full Name",
      placeholder: "Enter Full Name here",
      required: true,
    },
    {
      type: "email",
      value: email,
      name: "user_email",
      onchange: setEmail,
      title: "Email",
      placeholder: "Enter Email here",
      required: true,
    },
    {
      type: "text",
      value: contact,
      name: "user_contact",
      onchange: setContact,
      title: "Phone Number",
      placeholder: "Enter Phone Number here",
      required: true,
    },
    {
      type: "text",
      value: address,
      name: "user_address",
      onchange: setAddress,
      title: "Street Address",
      placeholder: "Enter Street Address here",
      required: true,
    },
    {
      type: "text",
      value: postcode,
      name: "user_postcode",
      onchange: setPostcode,
      title: "Postcode",
      placeholder: "Enter Postcode here",
      required: true,
    },
    {
      type: "select",
      value: service,
      name: "user_service",
      options: [
        "Architecture Enquiry",
        "Construction Quote",
        "Design & Build Package",
      ],
      onchange: setService,
      title: "Services",
      placeholder: "Select Service",
      required: true,
    },
    {
      type: "textarea",
      value: message,
      name: "user_message",
      onchange: setMessage,
      title: "How can we help you?",
      placeholder: "Enter your message here",
      required: false,
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "145037993",
          formId: "aca85b17-46d4-4ee7-b24c-6a6f619df1d9",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Paragon Architecture & Construction | Contact Us</title>
        <meta
          name="description"
          content="Paragon Architecture & Construction is your trusted local provider in Birmingham & surrounding areas. Contact us today for professional design and build services tailored to your needs. We're here to bring your vision to life!"
        />
      </Helmet>

      <div className="contact-container">
        <ContactBanner />
        <ContactInfoBox />
        <div className="contact-bottom-container">
          <div className="contact-bottom-left-container">
            <div className="contact-left-intro-div">
              <h1>
                GET A <span className="contact-free">FREE</span> QUOTE
              </h1>
              <p>
                Please fill out the enquiry form below and we will get back to
                you via our landline & email.
              </p>
            </div>
            <div className="contact-left-form-div">
              <form
                id="hubspotForm"
                className="form"
                onSubmit={handleSubmit}
                ref={form}
              >
                <div className="input">
                  {inputs.map((item, index) => (
                    <div className="field" key={index}>
                      <label>{item.title}</label>
                      {item.type === "textarea" ? (
                        <textarea
                          name={item.name}
                          value={item.value}
                          placeholder={item.placeholder}
                          onChange={(e) => item.onchange(e.target.value)}
                          required={item.required}
                        />
                      ) : item.type === "select" ? (
                        <select
                          name={item.name}
                          value={item.value}
                          onChange={(e) => item.onchange(e.target.value)}
                          required={item.required}
                        >
                          <option value="" disabled>
                            {item.placeholder}
                          </option>
                          {item.options.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          name={item.name}
                          type={item.type}
                          value={item.value}
                          placeholder={item.placeholder}
                          onChange={(e) => item.onchange(e.target.value)}
                          required={item.required}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <ReCAPTCHA
                  sitekey="6LdIqB4qAAAAAKhK6VBnToG604tjKyeb0yxGSruK"
                  onChange={handleRecaptchaChange}
                />
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
          <div className="contact-bottom-right-container">
            {/* <div className="contact-tps-btn-container">
              <p>
                During Design & Planning stages, you may need to enquire for
                additional services that are{" "}
                <span className="font-bold">NOT</span> included within our scope
                of work. Click here to find out all relevant Third Parties.
              </p>
              <div className="contact-tps-btn">
                <Link
                  to="/third-party-services"
                  className="contact-tps-btn-con"
                >
                  Third Party Services
                </Link>
              </div>
            </div> */}
            <div className="contact-area">
              <h1>Areas We Cover</h1>
              <p>
                Paragon specialises in projects across the regions highlighted
                below, offering exceptional service and in-depth expertise with
                local councils. Have a project outside these areas? No problem!
                We welcome enquiries from all locations and are committed to
                delivering a seamless experience, no matter where you’re based.
              </p>
              <div className="map">
                {/* <img src="/assets/images/map.png" alt="contact-map" /> */}
                <div style={{ width: "100%", height: "450px" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d311411.47976217454!2d-1.6547078!3d52.42657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9e32d19a81f1483%3A0xe27125607c48f289!2sParagon%20Architecture%20%26%20Construction!5e0!3m2!1sen!2suk!4v1732536777393!5m2!1sen!2suk"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Paragon Architecture & Construction Location"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
