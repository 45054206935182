import React from "react";
import "./ContactInfoBox.css";
import { Link } from "react-router-dom";

const ContactInfoBox = () => {
  return (
    <div className="contact-box-container">
      <div className="contact-box">
        <img src={"/assets/images/contact/contact_box_1.png"} alt="box-1" />
        <h1 className="conthrax">Opening Hours</h1>
        <div className="contact-opening-hrs">
          <span style={{ fontWeight: 300 }}>
            <p>Mon - Fri</p>
            <p>Sat</p>
            <p>Sun</p>
          </span>
          <span style={{ fontWeight: 500 }}>
            <p>09:00 - 17:00</p>
            <p>Appointments Only</p>
            <p>Closed</p>
          </span>
        </div>
      </div>
      <Link to="/third-party-services" target="_blank" rel="noopener noreferrer" className="contact-box">
        <img src={"/assets/images/contact/contact_box_2.png"} alt="box-1" />
        <h1 className="conthrax">Third Party Services</h1>
        <p className="para">
          During Design & Planning stages, you may need additional services that
          are <b>NOT</b> included within our scope of work. Click here to find
          out all relevant Third Parties.
        </p>
      </Link>
      <div className="contact-box">
        <img src={"/assets/images/contact/contact_box_3.png"} alt="box-1" />
        <h1 className="conthrax">Got Questions?</h1>
        <p className="para">
          Visit our FAQ page for quick answers & helpful insights about our
          services and process.
        </p>
      </div>
    </div>
  );
};

export default ContactInfoBox;
