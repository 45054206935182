import React, { useState, useEffect, useRef, useMemo } from "react";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import SideNav from "../SideNav";
import { CgMenu } from "react-icons/cg";

function Header({ toggleSidebar, sticky }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const dropdownRef = useRef(null);

  const data = useMemo(
    () => [
      { title: "Home", path: "/" },
      { title: "Services", path: "/services" },
      { title: "Projects", path: "/projects" },
      { title: "About", path: "/about" },
      { title: "Contact", path: "/contact" },
    ],
    []
  );

  const handleMouseEnter = (index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const currentIndex = data.findIndex(
      (item) => item.path === location.pathname
    );
    setActive(currentIndex);
    console.log(location.pathname);
  }, [location.pathname, data]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActive(null);
    }
  };

  useEffect(() => {
    if (active !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);

  const toggleSidebarVisibility = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className={`header ${sticky ? "sticky top-0 z-10 bg-white" : ""}`}>
      <div className="header-left" onClick={() => navigate("/")}>
        <img src="/assets/images/logo.png" alt="logo" className="header-logo" />
      </div>

      <div className="header-right">
        {data.map((item, index) => (
          <div
            key={index}
            className={`header-anime ${
              index === active || index === hovered ? "active" : ""
            } ${location.pathname === item.path ? "active" : ""} h-12`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation(item.path)}
          >
            <p>{item.title}</p>
          </div>
        ))}
        <CgMenu className="menu-icon" onClick={toggleSidebarVisibility} />
      </div>
      <SideNav show={showSidebar} toggleSidebar={toggleSidebarVisibility} />
    </div>
  );
}

export default Header;
