import React from "react";
import "./AboutParagon.css";
import ContactBtn from "../ContactBtn/ContactBtn";

const AboutParagon = () => {
  const data = [
    {
      title: "Designed for You",
      desc: "Every project—residential or commercial—is customised to meet your unique needs, style, and goals.",
    },
    {
      title: "Comprehensive Services",
      desc: "From initial design concepts to the final build, we manage every detail with precision.",
    },
    {
      title: "Seamless Collaboration",
      desc: "Our Architects and Construction Experts work together to deliver outstanding results.",
    },
    {
      title: "Functionality Meets Aesthetics",
      desc: "We ensure your space is both visually stunning and highly functional.",
    },
    {
      title: "On-Time, High-Quality Delivery",
      desc: "Your project is completed on schedule and built to the highest standards whilst adhering to your budget.",
    },
  ];
  return (
    <div className="about-paragon-container">
      <h1 className="conthrax">Why Choose Paragon ?</h1>
      <div className="about-paragon">
        {data.map((item) => (
          <div>
            <span>{item.title}</span>
            <p>{item.desc}</p>
          </div>
        ))}
        <h2>
          Whether it’s a dream home or a dynamic commercial space, we’re here to
          bring your vision to life.
        </h2>
        <div className="about-paragon-cta">
          <ContactBtn justify="center" text="FREE Quote" />
        </div>
      </div>
    </div>
  );
};

export default AboutParagon;
